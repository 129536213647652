<template>
  <v-row>
    <v-dialog
      v-model="modal"
      transition="scale-transition"
      scrollable
      persistent
      max-width="700px"
    >
      <v-card flat>
        <v-card-title>
          {{ $t("subjects.update_subjects") }}
          <div style="position: absolute; right: 25px">
            <v-btn icon @click="closeDialog">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-form ref="editSubjects" lazy-validation>
            <v-row class="mt-2">
              <v-col cols="12" sm="6">
                <v-text-field
                  :rules="[v => !!v || $t('app.required')]"
                  :label="$t('subjects.choose_subjects')"
                  :value="`${itemSubjects.lesson} ${getSub(itemSubjects.sub)}`"
                  readonly
                  dense
                  outlined
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="idSchoolYear"
                  :rules="[v => !!v || $t('app.required')]"
                  :label="$t('subjects.semester')"
                  :items="schoolYearList"
                  :item-text="
                    item =>
                      `${item.start_year} / ${item.end_year}, Semester ${item.semester}`
                  "
                  item-value="id"
                  dense
                  outlined
                  readonly
                  required
                >
                  <template v-slot:selection="{ item, index }">
                    <span v-if="index === 0" class="mr-2">{{
                      `${item.start_year} / ${item.end_year}, Semester ${item.semester}`
                    }}</span>
                    <span v-if="index === 1" class="grey--text">
                      (+{{ subject.semester.length - 1 }}
                      {{ $t("subjects.others") }})
                    </span>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="itemSubjects.kkm"
                  :rules="[v => !!v || $t('app.required')]"
                  :label="$t('subjects.kkm')"
                  type="number"
                  dense
                  outlined
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  :rules="[v => !!v || $t('app.required')]"
                  :label="$t('subjects.grade')"
                  :value="itemSubjects.grade"
                  readonly
                  dense
                  outlined
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete
                  v-model="itemSubjects.teacher"
                  :rules="[v => v.length > 0 || $t('app.required_array')]"
                  :label="$t('subjects.choose_teacher')"
                  :items="teacherList"
                  item-text="name"
                  multiple
                  dense
                  outlined
                  required
                  return-object
                >
                  <template v-slot:selection="{ item, index }">
                    <span v-if="index === 0" class="mr-2">{{ item.name }}</span>
                    <span v-if="index === 1" class="grey--text">
                      (+{{ itemSubjects.teacher.length - 1 }}
                      {{ $t("subjects.others") }})
                    </span>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="itemSubjects.hours_week"
                  :rules="[v => !!v || $t('app.required')]"
                  :label="$t('subjects.hours_week')"
                  type="number"
                  dense
                  outlined
                >
                  <template slot="append-outer">
                    <v-menu open-on-hover left offset-overflow>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on">
                          mdi-alert-circle-outline
                        </v-icon>
                      </template>
                      <v-card flat class="pa-2 elevation-0" width="500">
                        <v-img
                          src="https://sistesi.s3-ap-southeast-1.amazonaws.com/petunjuk/informasi-jumlah-mengajar.svg"
                        />
                      </v-card>
                    </v-menu>
                  </template>
                </v-text-field>
              </v-col>
              <v-col v-if="itemSubjects.type === 1" cols="12">
                <v-card class="my-6">
                  <v-card-title>
                    <v-text-field
                      v-model="searchStudent"
                      append-icon="mdi-magnify"
                      :label="
                        `${$t('app.search')} ${$t('app.student')} ${$t(
                          'app.class'
                        )} ${itemSubjects.class_name}`
                      "
                      hide-details
                      dense
                      outlined
                    ></v-text-field>
                  </v-card-title>
                  <v-data-table
                    v-model="modelTable"
                    :headers="tableHeaders"
                    :items="studentsList"
                    :search="searchStudent"
                    item-key="person"
                    hide-default-footer
                    show-select
                    class="elevation-0"
                    disable-pagination
                  >
                    <template #item.class>
                      <div>{{ className }}</div>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="px-6 pb-3">
          <v-spacer></v-spacer>
          <v-btn @click="closeDialog" color="primary" outlined class="mr-2">{{
            $t("app.back")
          }}</v-btn>
          <v-btn
            :loading="loading"
            color="gradient-primary"
            depressed
            dark
            @click="submit"
          >
            {{ $t("app.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    modal: Boolean,
    subjectList: Array,
    itemSubjects: Object,
    schoolYearList: Array,
    idSchoolYear: Number,
    teacherList: Array,
    loading: Boolean,
    studentsList: Array,
    tempStudent: Array
  },
  computed: {
    modelTable: {
      set(value) {
        this.setStudent(value);
      },
      get() {
        return this.tempStudent;
      }
    }
  },
  watch: {
    modal(value) {
      if (value) this.selectedStudent = [];
    }
  },
  data() {
    return {
      searchStudent: "",
      selectedStudent: [],
      tableHeaders: [
        {
          text: this.$i18n.t("app.student"),
          value: "name"
        }
      ]
    };
  },
  methods: {
    setStudent(students) {
      this.$emit("getStudents", students);
    },
    getSub: value => value ?? "",
    stringSubject: item => `${item.lesson} ${item.sub ?? ""}`,
    closeDialog() {
      this.$emit("close");
    },
    submit() {
      if (this.$refs.editSubjects.validate()) {
        this.$emit("save");
      }
    }
  }
};
</script>
